<template>
  <my-dv-page
      class="main"
      id="body"
      :width="1920"
      :height="1080"
      target="#app"
      :fullscreen="true"
      :style="{backgroundImage:`url('${ bgUrl }')`}"
  >
<!--    <ws-header></ws-header>-->
    <my-dv-box class="header" z-index="20" left="0" width="1920px" height="99px">
      <my-dv-box class="h-center" x-align="center" y-align="middle" content-align="center">
        <div class="text">数字可视化平台登录</div>
      </my-dv-box>
    </my-dv-box>
    <login-form></login-form>
  </my-dv-page>
</template>
<script>
import LoginForm from "@/components/common/LoginForm.vue";

export default {
  name: "Login",
  components: {LoginForm},
  data() {
    return {
      bgUrl: require('@/assets/images/login/login-bg.png')
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  .h-center {
    .text {
      font-size: 68px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #FFFFFF;
      position: relative;
      top: -10px;
    }
  }
}
</style>
