<template>
  <my-dv-box class="login-form-box" x-align="center" top="187px" width="723px" height="518px">
    <div class="login-form-title">系统登录</div>
    <div class="login-form">
      <div class="login-form-input" style="margin-top: 60px;">
        <img :src="require('@/assets/images/login/user-icon.png')" style="width: 22px;height: 25px;margin-left: 37px;" />
        <el-input v-model="user.name" placeholder="请输入用户名" clearable></el-input>
      </div>
      <div class="login-form-input"  style="margin-top: 27px;">
        <img :src="require('@/assets/images/login/lock-icon.png')" style="width: 23px;height: 28px;margin-left: 34px;" />
        <el-input v-model="user.pwd" show-password placeholder="请输入密码" clearable></el-input>
      </div>
      <button @click.prevent="handleLogin" class="btn">登录</button>
    </div>
  </my-dv-box>
</template>
<script>
import api from '@/utils/request'
export default {
  name: "LoginForm",
  data () {
    return {
      user: {
        name: '',
        pwd: ''
      }
    }
  },
  methods: {
    handleLogin () {
      if (!this.user.name.trim()) {
        this.$message.error('请输入用户名')
        return
      }
      if (!this.user.pwd.trim()) {
        this.$message.error('请输入密码')
        return
      }
      api.post('/login/doLogin', {
        username: this.user.name,
        password: this.user.pwd,
        plat: 3
      }).then(res => {
        const data = res.data
        if (data.code == 200) {
          localStorage.setItem('token', data.data.token)
          this.$router.replace('/')
        } else {
          this.$message.error(data.message)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .login-form-box {
    background-image: url("~@/assets/images/login/login-form-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .login-form-title {
      font-size: 36px;
      font-family: SourceHanSansSC-Bold;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 50px;
    }
    .login-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .login-form-input {
        background-image: url("~@/assets/images/login/form-input-bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 465px;
        height: 76px;
        display: flex;
        align-items: center;
      }
      .btn {
        margin-top: 36px;
        width: 464px;
        height: 56px;
        background: linear-gradient(0deg, #063B9C 0%, #55EEFF 100%);
        text-align: center;
        line-height: 56px;
        font-size: 32px;
        font-family: SourceHanSansSC-Bold;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
  button {
    border: 0!important;
  }
  ::v-deep .el-input input {
    background: none!important;
    border: none;
    color: #ffffff;
    font-size: 20px;
  }
  ::v-deep .el-input__inner:-webkit-autofill {
    transition: background-color 50000s ease-in-out 0s;
    -webkit-text-fill-color: #fff; //记住密码的颜色
    caret-color: #fff;//改变输入框光标颜色,同时又不改变输入框里面的内容的颜色
  }
</style>
